@font-face {
  font-family: "Roboto-Bold";
  src: url("./fonts/Roboto/Roboto-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("./fonts/Roboto/Roboto-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("./fonts/Roboto/Roboto-Regular.ttf");
  font-weight: 400;
}

.main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: "Roboto-Regular";
}
.sidebar {
  width: 50%;
  margin-right: 10px;
  text-align: left;
  padding: 10px;
  font-family: "Roboto-Bold";
  color: #696363;
  line-height: 2.4em;
  letter-spacing: 1px;
  /* border: 1px solid #000; */
}
.normal-text {
  position: relative;
  white-space: pre;
}
.hightlight-text {
  position: absolute;
  color: #000;
}

.main {
  width: 100%;
  /* border: 1px solid #000; */
}
.main h2 {
  font-family: "Roboto-Bold";
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* padding: 10px; */
}
.project {
  position: relative;
  width: 40%;
  margin: 10px;
  text-align: center;
  /* border: 1px solid #000; */
}
.hoverLayer {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  background-color: #000;
}
.project:hover .hoverLayer {
  opacity: 0.6;
}

.buttonLayer {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.project:hover .buttonLayer {
  opacity: 1;
}
a {
  text-decoration: none;
}

.ProfilePic {
  width: 6em;
  height: 6em;
  border-radius: 50px;
  /* border: 1px solid #000; */
}
.ProfilePic img {
  border-radius: 50px;
}

/* WORD ANIMATION */
.word {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  /* left: 13.8%; */
}

.w1 {
  animation: w1anim 10s infinite;
}

.w2 {
  animation: w2anim 10s infinite;
}

.w3 {
  animation: w3anim 10s infinite;
}

@keyframes w1anim {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  3% {
    opacity: 1;
    visibility: visible;
  }
  33% {
    opacity: 0.1;
    visibility: hidden;
  }
}

@keyframes w2anim {
  33% {
    opacity: 0;
    visibility: hidden;
  }
  36% {
    opacity: 1;
    visibility: visible;
  }
  60% {
    opacity: 0.1;
    visibility: hidden;
  }
}

@keyframes w3anim {
  60% {
    opacity: 0;
    visibility: hidden;
  }
  63% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0.1;
    visibility: hidden;
  }
}

@media only screen and (max-width: 940px) {
  .main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .sidebar {
    width: 100%;
    line-height: 2.4em;
    letter-spacing: 1px;
    /* border: 1px solid #000; */
  }
  .main {
    margin-top: 2em;
  }
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* padding: 10px; */
  }
  .project {
    position: relative;
    width: 100%;
    margin: 10px;
    text-align: center;
    /* border: 1px solid #000; */
  }
}
